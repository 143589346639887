import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

function NavBar() {
  const [menuOpen, setmenuOpen] = useState(false);
  const [alert, setAlert] = useState(true);
  return (
    <>
      {alert && (
        <div className="alert">
          <p>
            Iscrizioni chiuse per il concorso in presenza tranne per le scuole che hanno già preso contatti con la direzione artistica e per i licei musicali.
          </p>
        </div>
      )}

      <nav>
        <a href="https://concorsopaoloferro.edu.it">
          <div className="logo">
            <StaticImage src="../../images/logo.png" alt="Logo dashboard" />
          </div>
        </a>
        <div className="nav-links-w">
          <a href="https://concorsopaoloferro.edu.it/risultati/">Risultati</a>
          <a href="https://concorsopaoloferro.edu.it/regolamento-concorso-in-presenza/">
            Regolamento in presenza
          </a>
          <a href="https://concorsopaoloferro.edu.it/regolamento-concorso-online/">
            Regolamento Online
          </a>
          <a href="https://concorsopaoloferro.edu.it/#sezione-3-w">
            <button>Iscriviti</button>
          </a>
        </div>
        {menuOpen ? (
          <button
            style={{
              margin: 0,
              borderRadius: 30,
              border: "none",
              height: 40,
              width: 40,
            }}
            className="mobMenu-btn"
            onClick={() => {
              setmenuOpen(false);
            }}
          >
            <CloseOutlined style={{ fontSize: "18px" }} />
          </button>
        ) : (
          <button
            style={{
              margin: 0,
              borderRadius: 30,
              border: "none",
              height: 40,
              width: 40,
            }}
            className="mobMenu-btn"
            onClick={() => {
              setmenuOpen(true);
            }}
          >
            <MenuOutlined style={{ fontSize: "18px" }} />
          </button>
        )}
        {menuOpen && (
          <div className="mobNav-w">
            <a href="https://concorsopaoloferro.edu.it/risultati/">Risultati</a>
            <a href="https://concorsopaoloferro.edu.it/regolamento-concorso-in-presenza/">
              Regolamento in presenza
            </a>
            <a href="https://concorsopaoloferro.edu.it/regolamento-concorso-online/">
              Regolamento Online
            </a>
            <a href="https://concorsopaoloferro.edu.it/#sezione-3-w">
              <button>Iscriviti</button>
            </a>
          </div>
        )}
      </nav>
    </>
  );
}

export default NavBar;
